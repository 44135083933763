import { AxiosResponse } from 'axios';

import axios from '.';
import { Veterinario } from '../interfaces/Veterinario';

export const getVeterinariosBySiniestro = async (
  url: string,
  siniestro: string,
) => {
  console.log('sineistro',siniestro);
  try {
    const {
      data: veterinarios,
    }: AxiosResponse<Veterinario[]> = await axios.get(url, {
      params: {
        siniestro
      },
    });
    return veterinarios;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getVeterinarios = async (url: string) => {
  try {
    const {
      data: veterinarios,
    }: AxiosResponse<Veterinario[]> = await axios.get(url);
    return veterinarios;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getVeterinariosRecomendados = async (
  url: string,
  id: number,
  tipo: string
) => {
  try {
    const {
      data: veterinariosRecomendados,
    }: AxiosResponse<Veterinario[]> = await axios.get(url, {
      params: {
        id,
        tipo,
      },
    });
    return veterinariosRecomendados;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getVeterinario = async (
  url: string,
  veterinarioId: string,
) => {
  try {
    const {
      data: veterinario,
    }: AxiosResponse<Veterinario> = await axios.get(url, {
      params: {
        veterinarioId,
      },
    });
    return veterinario;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getVeterinarioVetform = async (
  url: string,
  vetformId: string,
) => {
  try {
    const {
      data: veterinario,
    }: AxiosResponse<Veterinario> = await axios.get(url, {
      params: {
        vetformId,
      },
    });
    return veterinario;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

